<template>
	<div :class="layoutClassComp">
		<copyText class="block w-1/1 fixed" />
		<div id="mainMenu" class="h-1/1 position-fixed shadow bg-white" v-b-hover="menuHover">
			<div id="mainMenuLogoWrap">
				<b-img id="mainMenuLogo" src="/img/logo.png" width="45" />
				<h4 id="mainMenuTitle">Hott</h4>
				<b-form-checkbox v-model="isMenuPin" switch />
			</div>
			<div
				v-for="(menuItem, i) in menuItems"
				v-if="$acl.check(menuItem.rule)"
				:key="menuItem.title + i"
				:class="menuItem.expand ? 'mainMenuExpanded' : ''"
				class="mainMenuItemWrap"
			>
				<div :class="getMenuItemClass(menuItem)" @click="menuSelect(menuItem)">
					<b-icon class="menuItemIcon" :icon="menuItem.icon" font-scale="1.3" />
					<span class="menuItem">{{ menuItem.title }}</span>
					<b-icon
						v-if="menuItem.children"
						class="menuArrowIcon"
						:icon="menuItem.expand ? 'chevron-down' : 'chevron-right'"
					/>
				</div>
				<div
					v-for="(subMenu, j) in menuItem.children"
					v-if="$acl.check(subMenu.rule)"
					:key="subMenu.title + i"
					:class="getMenuItemClass(subMenu)"
					class="subMenuItemWrap"
					@click="menuSelect(subMenu)"
				>
					<b-icon-circle class="subMenuIcon" font-scale="0.6" />
					<span class="menuItem">{{ subMenu.title }}</span>
				</div>
			</div>
		</div>
		<div id="mainContentWrap" class="position-fixed h-1/1">
			<div id="navMenuWrap" class="mt-4">
				<span v-text="user.username" />
				<b-dropdown no-caret right variant="link">
					<template #button-content>
						<b-avatar :src="user.profileImage" />
					</template>
					<b-dropdown-item @click="logout">로그아웃</b-dropdown-item>
				</b-dropdown>
			</div>
			<div id="breadcrumbWrap" class="px-4 flex-between flex-mid mt-3">
				<h4 class="mb-0">{{ pageTitle || $route.meta.pageTitle }}</h4>
				<b-breadcrumb>
					<b-breadcrumb-item href="home">
						<b-icon icon="house-door" scale="1.25" shift-v="1.25" />
						Home
					</b-breadcrumb-item>
					<b-breadcrumb-item
						v-for="(breadcrumb, i) in $route.meta.breadcrumb"
						:key="breadcrumb.text + i"
						:active="breadcrumb.active"
						>{{ breadcrumb.text }}
					</b-breadcrumb-item>
				</b-breadcrumb>
			</div>
			<h6 v-show="$route.meta.exp" class="px-4 mt-3" v-text="$route.meta.exp" />
			<!--라우터 뷰 시작. 중복 컴포넌트 처리에서 퍼포먼스가 좀 떨어지더라도 여기에 키를 설정하는 게 라우터 watch하는 것보다는 훨씬 낫지 않나..?-->
			<!--keep-alive는 버그가 좀 있는 편인듯하므로 일단 파트너사 리스트에만 적용-->
			<keep-alive include="keep">
				<router-view :key="$route.fullPath" class="p-4" />
			</keep-alive>
		</div>

		<memoPopup :pr="ths" />
		<alert :pr="ths" />
		<b-spinner v-show="isLoading" id="loadingSpinner" variant="primary" />

		<div v-if="isPartner" id="channeltalk_btn" class="quick_consulting" />
	</div>
</template>

<script>
import menuItems from 'pages/libs/menuItems'
import memoPopup from 'comp/memoPopup'
import alert from 'comp/alert'
import jwt from 'pages/libs/jwt'
import copyText from 'comp/copyText'

export default {
	mixins: [copyText],
	components: { memoPopup, alert, copyText },
	data() {
		return {
			isFullPage: false,
			isMenuPin: true,
			isMouseEnterMenu: false,
			menuItems: menuItems(false),
			isLoading: false,
			userUpdateFl: 0,
			pageTitle: '',
		}
	},
	mounted() {
		setTimeout(() => {
			this.menuItems.map(p => {
				if (p.children) {
					p.children.map(v => {
						if (this.$route.path == '/') return
						if (this.$route.path.startsWith(v.route)) p.expand = true
						else if (this.$route.meta.parent == v.parent) p.expand = true
					})
				}
			})
			//라우터가 바인딩되는 속도가 생각보다 늦다
		}, 500)
	},
	methods: {
		menuHover(isEnter) {
			this.isMouseEnterMenu = isEnter
		},
		menuSelect(item) {
			if (item.children) item.expand = !item.expand
			else page({ path: item.route })

			setTimeout(() => {
				this.menuItems.map(v => {
					if (v.children && v != item) {
						let isExpand = false
						v.children.map(c => {
							if (c == item) isExpand = true
						})
						v.expand = isExpand
					}
				})
			}, 100)
		},
		getMenuItemClass(item) {
			const path = this.$route.path
			if (path == '/') return ''
			if (item.children) {
				const ct = item.children.length
				for (let i = 0; i < ct; i++) {
					if (path.startsWith(item.children[i].route)) {
						return 'selectedMenuParent'
						break
					} else if (this.$route.meta.parent == item.children[i].parent) {
						return 'selectedMenuParent'
						break
					}
				}
				return ''
			}
			return path.startsWith(item.route) || this.$route.meta.parent == item.parent ? 'selectedMenu' : ''
		},
		logout() {
			jwt.logout()
		},
	},
	computed: {
		layoutClassComp() {
			if (this.isFullPage) return 'fullPage'
			if (this.isMenuPin) return 'menuPin'
			if (this.isMouseEnterMenu) return 'menuExpand'
			return ''
		},
		user() {
			const a = this.userUpdateFl
			return jwt.getUserInfo()
		},
		isPartner() {
			return env.server == 'partner'
		},
	},
}
</script>

<style>
html,
body,
#layout {
	height: 100%;
}

#mainMenu {
	z-index: 99;
	width: 60px;
	padding: 0 5px;
	overflow: hidden;
	overflow-y: scroll;
	user-select: none;
	transition: all 0.15s ease;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

#mainMenu::-webkit-scrollbar {
	display: none;
}

.menuExpand #mainMenu,
.menuPin #mainMenu {
	width: 260px;
	transition: all 0.15s ease;
}

.fullPage #mainMenu {
	display: none;
}

#mainMenu #mainMenuLogoWrap {
	width: 250px;
	height: 55px;
	margin: 20px 0 10px;
}

#mainMenu #mainMenuLogoWrap * {
	display: inline-block;
	vertical-align: middle;
}

#mainMenu #mainMenuLogo {
	width: 35px;
	margin: 5px 10px;
}

#mainMenu #mainMenuTitle {
	width: 155px;
	padding-left: 5px;
	font-weight: bold;
}

#mainMenu #mainMenuLogoWrap .custom-switch {
	top: -13px;
}

#mainContentWrap {
	z-index: 98;
	left: 60px;
	width: calc(100% - 60px);
	min-width: 1400px;
	overflow: scroll;
	background-color: var(--light);
	transition: all 0.15s ease;
}

#mainContentWrap .breadcrumb {
	margin-bottom: 0;
	padding: 0;
	background-color: transparent;
}

.menuPin #mainContentWrap {
	left: 260px;
	width: calc(100% - 260px);
	transition: all 0.15s ease;
}

.fullPage #mainContentWrap {
	left: 0;
	width: 100%;
}

.mainMenuItemWrap {
	width: 250px;
	min-height: 1.9em;
	margin: 10px 0;
	cursor: pointer;
}

.mainMenuItemWrap > div {
	transition: all 0.15s ease;
}

.mainMenuItemWrap > div:hover {
	padding-left: 10px;
}

.menuItemIcon {
	margin-right: 17px;
	margin-left: 17px;
}

.menuItem {
	line-height: 2em;
	font-size: 0.95em;
	font-weight: 500;
}

.menuArrowIcon {
	position: absolute;
	top: 6px;
	right: 15px;
}

.subMenuItemWrap {
	height: 0;
	margin-left: 10px;
	overflow: hidden;
	transition: all 0.15s ease;
}

.mainMenuExpanded .subMenuItemWrap {
	height: 31px;
	margin: 5px;
}

.mainMenuExpanded .subMenuItemWrap .menuItem {
	top: 2px;
	font-size: 0.85em;
}

.subMenuIcon {
	top: 1px;
	margin-right: 20px;
	margin-left: 20px;
	vertical-align: baseline !important;
}

.selectedMenuParent {
	background-color: #eee;
	border-radius: 5px;
	box-shadow: 0 0 1em #eee;
}

.selectedMenu {
	background-color: var(--primary);
	border-radius: 5px;
	box-shadow: 0 0 0.3em var(--primary);
}

.selectedMenu * {
	color: white;
}

#navMenuWrap {
	text-align: right;
}

.fullPage #navMenuWrap,
.fullPage #breadcrumbWrap {
	display: none !important;
}

#loadingSpinner {
	position: fixed;
	z-index: 99999;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
}

#channeltalk_btn {
	position: fixed;
	z-index: 99;
	right: 1rem;
	bottom: 3.7rem;
	width: 4rem;
	height: 4rem;
	cursor: pointer;
	background: url(/img/channeltalk_btn.png) no-repeat center / contain;
}
</style>
