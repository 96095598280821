//좋은 방식은 아닌데.. parent를 이런 식으로 안 맺어주면 부모 opt를 초기화할 때 연결이 끊어져버린다...
function isSet(target, path, ct) {
	for (let i = 0; i < ct; i++) {
		if (target[path[i]] !== undefined) target = target[path[i]]
		else return false
	}
	return true
}

export default {
	props: { model: { type: String } },
	data() {
		return {
			key: '',
		}
	},
	computed: {
		compParent() {
			let target = this.p
			const path = this.model.split('.'),
				ct = path.length

			this.key = path[ct - 1]

			for (let i = 0; i < 5; i++) {
				if (isSet(target, path, ct)) break
				else target = target.$parent
			}

			for (let i = 0; i < ct - 1; i++) target = target[path[i]]
			return target
		},
		compModel: {
			get() {
				return this.compParent[this.key]
			},
			set(val) {
				this.compParent[this.key] = val
			},
		},
	},
}
