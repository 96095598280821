<template>
	<datepicker class="datePicker" v-model="compModel" :placeholder="placeholder" :language="ko" format="yyyy-MM-dd" />
</template>

<script>
import { ko } from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker'
import calcParent from 'comp/local/calcParent.js'

export default {
	mixins: [calcParent],
	props: { placeholder: { default: '' } },
	components: { Datepicker },
	data() {
		return {
			ko,
		}
	},
}
</script>
