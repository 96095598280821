<template>
	<div :key="keyForUpdate" class="vtb">
		<div v-for="(tr, i) in inf" class="tr lh-9">
			<div v-for="(v, j) in tr" :class="getTrClass(v)" class="wrap inline-block">
				<div :class="getThClass(v)" class="th inline-block">
					{{ v.title }}
					<b-button
						v-if="v.tooltip"
						class="p-0 ml-2 -mt-1"
						v-b-tooltip.hover.html
						:title="v.tooltip"
						size="sm"
						variant="no"
					>
						<b-icon icon="info-circle" />
					</b-button>
				</div>
				<div :class="getTdClass(v)" class="td inline-block">
					<p v-if="!v.model" v-html="sel(v.cont, v, '&nbsp;')" />
					<button
						v-else-if="v.model == 'button'"
						:class="sel(v.class, v, '') + (v.bVariant ? 'btn-' + v.bVariant : 'btn-primary')"
						class="btn"
						@click.stop.prevent="sel(v.func, v, '')"
						type="button"
						v-text="sel(v.cont, v, '')"
					/>
					<input
						v-else-if="v.model == 'input'"
						:class="sel(v.addClass, v, '')"
						class="form-control"
						v-model.trim="v.obj[v.key]"
						:name="v.key"
						:placeholder="v.placeholder"
						@blur="sel(v.focusOut, v, '')"
						@focusin="sel(v.focusIn, v, '')"
						@keyup="sel(v.func, v, '', false)"
						@keyup.enter="sel(v.enter, v, '')"
						:disabled="sel(v.disabled, v, false)"
						type="text"
					/>
					<a
						v-else-if="v.model == 'url' && sel(v.url, v, v.cont)"
						:href="sel(v.url, v, v.cont)"
						target="_blank"
					>
						{{ v.cont ? v.cont : '&nbsp;' }}
					</a>
					<vSelect
						v-else-if="v.model == 'dropdown'"
						:key="v.obj.keyy"
						:class="sel(v.addClass, v, '')"
						v-model="v.obj[v.key]"
						@input="vSelectFunc(v)"
						:clearable="false"
						:disabled="sel(v.disabled, v, false)"
						:options="v.options"
					/>
					<slot v-else-if="v.model" :name="v.model" :i="i" :j="j" :v="v" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		cName: { default: 'vtb' },
		oneColumn: { default: false },
		data: { default: [] },
		titleSize: { default: 4 },
	},
	//titleSize: 1~12, fullWidth가 있는 경우 짝수로 설정
	data() {
		return {
			keyForUpdate: 0,
		}
	},
	methods: {
		sel,

		getTrClass(v) {
			let res = v.isFull ? 'w-1/1 ' : 'w-1/2 '
			return (res += v.trClass ?? '')
		},
		getThClass(v) {
			let res = `w-${v.titleW}/12 `
			return (res += v.thClass ?? '')
		},
		getTdClass(v) {
			let res = `w-${v.contW}/12 `
			return (res += v.tdClass ?? '')
		},
		forceUpdate() {
			this.keyForUpdate++
		},

		vSelectFunc(v) {
			sel(v.func, v, '')
			this.forceUpdate()
			//이게 그냥은 업데이트가 안 된다...?
		},
	},
	computed: {
		inf() {
			const ct = this.data.length,
				res = []
			if (!ct) return res

			let currentLine = 0,
				currentLineItemCt = 0,
				//현재 라인에 들어간 아이템 개수
				isNewLine = true,
				isFull = false
			for (let i = 0; i < ct; i++) {
				const v = this.data[i]
				if (typeof v.title == 'undefined') v.title = ''
				if (typeof v.cont == 'undefined') v.cont = ''

				if (v.isFull || this.oneColumn !== false)
					//현재 아이템에 fullWidth가 지정되어 있거나 테이블 자체가 한줄씩 보여주는 걸로 생성된 경우
					isFull = true
				else if (!currentLineItemCt) {
					//현재 라인에 아무것도 없으면서
					const nextIdx = i + 1
					if (nextIdx < ct) {
						if (this.data[nextIdx].isFull)
							//다음 라인에 fullWidth가 지정되어 있는 경우
							isFull = true
					}
					//마지막 줄인 경우
					else isFull = true
				}

				if (this.oneColumn === false && isFull) v.titleW = Math.round(this.titleSize / 2)
				else v.titleW = this.titleSize
				v.contW = 12 - v.titleW

				v.isFull = isFull
				if (isNewLine) res.push([v])
				else res[currentLine].push(v)

				if (isFull || currentLineItemCt) {
					//다음 아이템은 다음 줄로 들어감
					currentLine++
					currentLineItemCt = 0
					isNewLine = true
				} else {
					currentLineItemCt = 1
					isNewLine = false
				}
				isFull = false
			}
			return res
		},
	},
}
</script>
