<template>
	<b-modal
		v-model="isShow"
		:buttonsHidden="true"
		:title="`${title} ${isReadOnly ? '변경내역' : '메모 목록'}`"
		centered
		hide-footer
		size="lg"
	>
		<div v-if="inputCategoryOpts.length" class="flex mb-2">
			<b-form-radio
				v-for="(v, i) in inputCategoryOpts"
				class="mr-6"
				v-model="inputCategory"
				name="memoSearchRadio"
				:value="v.value"
			>
				{{ v.label }}
			</b-form-radio>
		</div>
		<b-form-textarea
			v-if="!isReadOnly"
			class="inline-block w-4/5 resize-none"
			v-model="comment"
			:placeholder="kindKr + '에 대한 메모를 작성해 주세요.'"
			rows="5"
		/>
		<div v-if="!isReadOnly" class="inline-block w-1/5 align-top pl-4">
			<b-button class="block px-10" @click="save" v-text="editMemoSeq == -1 ? '저장' : '수정'" />
			<b-button class="block mt-4 px-10" @click="cancel(false)" variant="gray">취소</b-button>
		</div>

		<b-card class="p-2 py-3 my-2" cName="filterCard">
			<h4>{{ isReadOnly ? '변경 내역' : '메모 목록' }}</h4>
			<div class="absolute -top-1 right-0 flex pl-8">
				<v-select
					v-if="!isReadOnly"
					class="w-60"
					v-model="opt.category"
					:clearable="false"
					:options="memoOpts"
				/>
				<b-form-input
					class="w-80 mx-2"
					v-model="opt.searchValue"
					:placeholder="`${isReadOnly ? '' : '메모 '}내용 검색`"
					v-on:keyup.enter="search"
				/>
				<b-button :class="!isReadOnly ? 'mr-4' : ''" class="px-10" @click="search">검색</b-button>
				<b-button v-if="!isReadOnly" @click="excelExport" variant="info">엑셀 다운로드 요청</b-button>
			</div>

			<!--테이블 시작-->
			<tb class="mt-4" :inf="inf" :pr="ths" :res="res" limit="5" />
		</b-card>
	</b-modal>
</template>

<script>
const defaultData = {
	isShow: false,
	isReadOnly: false,

	title: '',
	kindKr: '',

	domain: '',
	identifier: '',
	inputCategory: 'M1',
	inputCategoryOpts: [],
	comment: '',
	editMemoSeq: -1,
	categoryOpts: [],

	opt: { searchValue: '', category: { label: '전체', value: '' } },
	lastOpt: {},
	res: {},
}

export default {
	props: { cName: { default: 'memoPopup' } },
	data() {
		return {
			...defaultData,
			info: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '메모 내용', key: 'memo', size: 15, notCenter: true },
				{ title: '작성일', key: 'createDt', size: 4 },
				{ title: '작성자', key: 'adminName', size: 2 },
				{
					title: '수정/삭제',
					model: 'editDelete',
					size: 2,
					func1: this.editMemo,
					func2: this.remove,
				},
			],
			baseOpts: '',
		}
	},
	methods: {
		initOpts() {
			if (this.baseOpts)
				return new Promise(resolve => {
					resolve(this.baseOpts)
				})
			return getConfig('memo_category').then(res => (this.baseOpts = res.memo_category))
		},
		open(domain = 'MEMBER', item = {}, head = {}) {
			//초기화
			const isReadOnly = head.readOnly
			Object.keys(defaultData).map(k => {
				if (typeof defaultData[k] != 'object') this[k] = defaultData[k]
				else if (Array.isArray(defaultData[k])) this[k] = [...defaultData[k]]
				else this[k] = { ...defaultData[k] }
			})
			this.isReadOnly = isReadOnly
			this.info[1].title = isReadOnly ? '변경 내역' : '메모 내용'
			//.......

			this.domain = domain

			this.comment = ''
			this.inputCategory = 'M1'
			this.inputCategoryOpts = []

			switch (domain) {
				case 'STORE':
					this.kindKr = '파트너사'
					this.identifier = item.storeSeq
					this.title = item.comName ?? item.storeName
					break
				case 'DEAL_REQUEST_HISTORY':
					this.kindKr = '딜 신청'
					this.identifier = item.idx
					//dealRequestIdx
					this.title = `${item.idx} / ${item.brandName} ${item.productGroupName} / ${item.hottId}`
					break
				case 'PRODUCT_GROUP':
					this.kindKr = '협찬'
					this.inputCategory = 'M14'
					this.inputCategoryOpts = [
						{ label: '일반', value: 'M1' },
						{ label: '핫트 협찬', value: 'M14' },
					]
					this.identifier = item.productGroupIdx
					this.title = `${item.comName} ${item.dealName}`
					break
				case 'USER_ORDER':
					this.kindKr = '주문'
					this.identifier = item.orderNum
					this.title = item.orderNum + '주문'
					break
				case 'ORDER_ITEM':
					this.kindKr = '주문 항목'
					this.identifier = item.seqNo
					this.title = item.productName ?? '주문 항목 메모'
					break
				case 'SPONSORED_DELIVERY':
					this.kindKr = '협찬품'
					this.identifier = item.dealRequestIdx
					this.title = '협찬품'
					break
				case 'COUPON':
					this.kindKr = '쿠폰'
					this.identifier = item.couponSeq
					this.title = '쿠폰'
					break
				default:
					//MEMBER
					//HOTTIST_HISTORY
					this.kindKr = '핫티스트'
					this.identifier = item.memberSeq
					this.title = `${item.memberName} / ${item.hottId} / ${item.currentInstaId}`

					if (!isReadOnly)
						this.inputCategoryOpts = [
							{ label: '일반', value: 'M1' },
							{ label: '딜 심사', value: 'M12' },
						]

					if (head.isDealMemo) {
						this.comment = `[딜 심사] ${item.openDealNum} / ${item.brandName} ${item.productGroupName}\r\n`
						this.inputCategory = 'M12'
						this.opt.category = { label: '딜 심사', value: 'M12' }
					}
			}

			if (!this.identifier) return alert.w('필수값이 없습니다.\r\nidentifier')
			this.search()
			this.isShow = true
		},
		search() {
			const opt = this.opt,
				category = getDropdownValue(opt.category),
				searchValue = opt.searchValue,
				data = {
					domain: this.domain,
					identifier: this.identifier,
					paging: getPaging(0, 5),
					orderBy: getOrder('createDt'),
				}
			if (!this.isReadOnly && category) data.category = category
			if (searchValue) data.searchValue = searchValue

			this.lastOpt = data
			this.initOpts().then(() => this.changePage(1))
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/memoSelect/memoList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list.map(v => {
						v.createDt = v.createDt.substr(0, 10)

						//메모 카테고리 적용
						v.memo = v.category != 'M1' ? `[${getOptsLabel(this.baseOpts, v.category)}]\r\n` : ''
						v.memo += v.comment
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		editConfirm(text, func, isForceUpdate = false) {
			if (!isForceUpdate && this.editMemoSeq != -1)
				confirm(`작성하던 내용이 삭제됩니다. ${text}하시겠습니까?`, func)
			else func()
		},
		editMemo(v) {
			this.editConfirm('계속 진행', () => {
				this.editMemoSeq = v.generalMemoSeq
				this.comment = v.comment
			})
		},
		remove(v) {
			confirm('메모를 삭제하시겠습니까?', () => {
				putApi('api/backoffice/memoHandle/deleteMemo', { generalMemoSeq: v.generalMemoSeq }).then(() => {
					//if (this.editMemoSeq == v.store) this.cancel(true)
					this.changePage(this.currentPage)
				})
			})
		},
		save() {
			if (this.comment.length > 0) {
				let data = {
						adminSeq: layout.user.seqNo,
						domain: this.domain,
						comment: this.comment,
					},
					url,
					api
				if (this.editMemoSeq == -1) {
					//메모 등록
					url = 'api/backoffice/memoHandle/createMemo'
					data.identifier = this.identifier
					data.category = this.inputCategory
					api = postApi
				} else {
					//메모 수정
					url = 'api/backoffice/memoHandle/updateMemo'
					data.generalMemoSeq = this.editMemoSeq
					api = putApi
				}

				api(url, data).then(v => {
					this.cancel(true)
					this.changePage(this.currentPage)
				})
			} else alert.w('메모를 입력해주세요')
		},
		cancel(isForceUpdate = false) {
			this.editConfirm(
				'취소',
				() => {
					this.editMemoSeq = -1
					this.comment = ''
				},
				isForceUpdate
			)
		},

		excelExport() {
			const opt = this.lastOpt,
				ex = new excel(this.kindKr + ' 메모')

			const category = getOptsFromValue(this.memoOpts, opt.category)
			ex.key('카테고리', category.length ? category.label : '전체')

			if (opt.searchValue) ex.key('검색어', opt.searchValue)

			ex.go(this)
		},
	},
	computed: {
		currentPage() {
			return this.res.list ? this.res.paging.currentPage + 1 : 1
		},
		inf() {
			const ct = this.isReadOnly ? 4 : 99
			return this.info.slice(0, ct)
		},
		memoOpts() {
			if (!this.baseOpts || this.isReadOnly) return []

			let res = [],
				append
			switch (this.domain) {
				case 'STORE':
					res = this.baseOpts.slice(2, 8)
					append = getOptsFromValue(this.baseOpts, 'M13')
					if (append.length) res = [...this.baseOpts.slice(0, 1), ...res, append]
					break
				case 'DEAL_REQUEST_HISTORY':
					return []
				case 'PRODUCT_GROUP':
					res = this.baseOpts.slice(0, 2)
					append = getOptsFromValue(this.baseOpts, 'M14')
					if (append.length) res = [...res, append]
					break
				default:
					//MEMBER
					//HOTTIST_HISTORY
					res = [...this.baseOpts.slice(0, 13)]
			}
			return res
		},
	},
}
</script>
