<template>
	<input id="copyTextEl" style="height: 1px; opacity: 0" type="text" />
</template>

<script>
export default {
	data() {
		return {
			isIphone:
				navigator.platform.indexOf('iPhone') != -1 ||
				navigator.platform.indexOf('iPod') != -1 ||
				navigator.platform.indexOf('iPad') != -1,
			copyTextEl: '',
		}
	},
	methods: {
		copyText(val = '', alertMsg, autoCloseTime) {
			const el = this.copyTextEl
			el.value = val

			if (this.isIphone) {
				const editable = el.contentEditable
				const readOnly = el.readOnly

				el.contentEditable = true
				el.readOnly = true

				const range = document.createRange()
				range.selectNodeContents(el)

				const selection = window.getSelection()
				selection.removeAllRanges()
				selection.addRange(range)
				el.setSelectionRange(0, 999999)

				el.contentEditable = editable
				el.readOnly = readOnly
			} else el.select()
			document.execCommand('copy')
			if (alertMsg) alert.s(alertMsg, autoCloseTime)
		},
	},
	mounted() {
		this.copyTextEl = document.getElementById('copyTextEl')
	},
}
</script>
