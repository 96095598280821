<template>
	<div :key="keyForUpdate" class="tb">
		<p class="my-2" v-text="title" />
		<table :class="{ 'table-striped': isStriped, 'table-hover': isHover }" class="table b-table table-sm border">
			<thead class="thead-light">
				<tr>
					<th v-for="h in info" :class="h.thClass" @click.stop="changeOrder(h)" :aria-sort="getAriaSort(h)">
						<p v-if="h.model != 'checkAll'" v-html="h.title" />
						<b-form-checkbox
							v-else
							class="baseCheckBoxAll"
							v-model="checkAllStatus"
							@change="clickCheckAll"
						/>
						<b-button
							v-if="h.tooltip"
							class="tbTooltip"
							v-b-tooltip.hover.html
							:title="h.tooltip"
							size="sm"
							variant="no"
						>
							<!--헤드에 표시될 툴팁이 있는 경우-->
							<b-icon icon="info-circle" />
						</b-button>
					</th>
				</tr>
			</thead>
			<tbody v-if="totalLength">
				<tr v-for="(line, i) in lines" :class="getTrClass(line, info[i], i)" @click.stop="selectRow(line, i)">
					<cTd
						v-for="(k, j) in keys"
						v-if="isShowTd(line.opt, k)"
						:class="getTdClass(line, info[j], k)"
						class="align-middle"
						:colspan="getColspan(line.opt[k])"
						:h="info[j]"
						:i="i"
						:j="j"
						:k="k"
						:line="line"
						:rowspan="getRowspan(line.opt[k])"
					>
						<!--체크박스-->
						<template #checkAll="{ h, v }">
							<b-form-checkbox
								class="baseCheckBox"
								:name="line.lineOpt.lineIdx.toString()"
								@change="clickCheck(v)"
								:checked="isCheck(v)"
							/>
						</template>

						<!--인덱스-->
						<template #idx>
							{{ line.lineOpt.itemIdx }}
						</template>

						<!--링크-->
						<template #url="{ h, v }">
							<a
								v-if="sel(h.url, v, v[h.key])"
								:href="sel(h.url, v, v[h.key])"
								:target="sel(h.target, v, '_blank')"
							>
								{{ sel(h.url, v, v[h.key]) }}
								<div
									v-if="h.copyBtn"
									class="btn"
									@click.stop.prevent="copyText(sel(h.url, v, v[h.key]))"
								>
									<b-icon font-scale="1" icon="files" />
								</div>
							</a>
						</template>

						<!--링크랑 텍스트랑 다른 경우-->
						<template #urlText="{ h, v }">
							<a v-if="v[h.url]" :href="v[h.url]" :target="sel(h.target, v, '_blank')">
								{{ v[h.text] }}
							</a>
						</template>

						<!--인스타 링크-->
						<template #insta="{ h, v }">
							<a
								v-if="getInstaUrl(v[h.key])"
								:href="getInstaUrl(v[h.key])"
								:target="sel(h.target, v, '_blank')"
							>
								{{ v[h.key] }}
							</a>
						</template>

						<!--체크박스-->
						<template #checkBox="{ h, v }">
							<b-form-checkbox
								v-model="v[h.key] && v[h.key].cont ? v[h.key].cont : v[h.key]"
								:name="h.key"
								:disabled="sel(h.disabled, v, false)"
								@change="h.func(v)"
							/>
						</template>

						<!--드롭다운 리스트-->
						<template #dropdown="{ h, v }">
							<vSelect
								:class="sel(h.addClass, v, '')"
								v-model="v[h.key]"
								@input="h.func(v)"
								:clearable="false"
								:disabled="sel(h.disabled, v, false)"
								:options="h.options"
							/>
						</template>

						<!--입력 박스-->
						<template #input="{ h, v }">
							<input
								:class="sel(h.addClass, v, '')"
								class="form-control"
								v-model.trim="v[h.key]"
								:name="h.key"
								:placeholder="h.placeholder"
								@blur="sel(h.focusOut, v, '')"
								@focusin="sel(h.focusIn, v, '')"
								@keyup="sel(h.func, v, '', false, h)"
								@keyup.enter="sel(h.enter, v, '')"
								:disabled="sel(h.disabled, v, false)"
								type="text"
							/>
						</template>

						<!--버튼-->
						<template #button="{ h, v }">
							<b-button
								:class="sel(h.class, v, '')"
								@click.stop.prevent="sel(h.func, v, '')"
								:size="sel(h.bSize, v, 'sm')"
								:variant="h.icon ? 'no' : sel(h.bVariant, v, 'secondary')"
							>
								{{ sel(h.text, v, '') }}
								<b-icon v-if="h.icon" :icon="sel(h.icon, v, 'three-dots')" font-scale="1" />
							</b-button>
						</template>

						<template #editDelete="{ h, v }">
							<div
								v-if="sel(h.vIf1, v, true)"
								class="btn w-1/2 inline-block"
								@click.stop.prevent="sel(h.func1, v, '')"
							>
								<b-icon font-scale="1" icon="pencil-square" />
							</div>
							<div
								v-if="sel(h.vIf2, v, true)"
								class="btn w-1/2 inline-block"
								@click.stop.prevent="sel(h.func2, v, '')"
							>
								<b-icon font-scale="1" icon="trash" />
							</div>
						</template>

						<!--메모-->
						<template #memoBtn="{ v, h }">
							<div class="btn" @click.stop.prevent="openMemo(v, h)">
								<b-icon :icon="h.readOnly ? 'clock-history' : 'stickies'" font-scale="1" />
							</div>
						</template>

						<!--기본셀-->
						<template #default="{ h, v, i, j }">
							<slot :name="sel(h.model, v, 'default')" :h="h" :i="i" :j="j" :v="v">
								<!--부모 템플릿 참조하는 슬롯. 이것도 없으면 기본값 p 사용-->
								<p v-html="sel(v[h.key], v)" />
							</slot>
						</template>
					</cTd>

					<td
						v-if="child && line.opt.isChild"
						:class="{ 'd-none': !line.opt.isShow }"
						class="align-middle baseChildTd pt-2 px-4"
						:colspan="inf.length"
					>
						<!--:h="line.cont" :p="c.p"-->
						<!--자식이 있을 때만 들어온다-->
						<slot
							v-if="line.opt.isShow"
							:name="sel(child.model, line.p, 'child')"
							:h="child"
							:parent="line.p"
							:v="line.item"
						>
							<!--부모에서 커스텀할 때-->
							<!--<template #model="{ h, v }"></template>-->
							<tb
								:class="child.tbClass"
								@changeOrder="childChangeOrder"
								@changePage="childChangePage"
								@click="child.selectItem"
								:inf="child.inf"
								:isChild="true"
								:isStriped="sel(child.isStriped, line.p, true)"
								:limit="sel(child.limit, line.p, 10)"
								:nav="sel(child.nav, line.p, 'list')"
								:parentItem="line.p"
								:pr="ths"
								:res="line.item.res"
								:selectable="sel(child.selectable, line.p, false)"
								:title="line.item.title"
								cName="childTb"
							/>
							<!--기본값은 다시 child tb 생성-->
						</slot>
					</td>
				</tr>
				<tr v-if="nav == 'more' && currentPage < totalPage">
					<td
						class="align-middle text-center bg-white cursor-pointer"
						@click.stop.prevent="currentPage += 1"
						:colspan="inf.length"
						v-text="'+ 내용 더보기'"
					/>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td class="text-center py-4" :colspan="inf.length">
						<slot name="noList">
							<slot name="noList">표시할 항목이 없습니다</slot>
						</slot>
					</td>
				</tr>
			</tbody>
		</table>
		<b-pagination
			v-if="nav == 'list'"
			class="user-select-none"
			v-model="currentPage"
			:total-rows="totalPage"
			limit="10"
			per-page="1"
		/>
	</div>
</template>

<script>
import cTd from 'comp/local/td'
//thClass는 inf에
//trClass는 item에 직접 담길 수도 있고 props로 받을 수도 있다
//tdClass는 inf + res에

//colspan, rowspan은 res 내부에 있어야 하고 객체 형태로 들어와야 한다
//{ cont, colspan, rowspan, ... }
//그러면 여기서는 computed에서 객체의 cont를 사본 배열에 붙여서 계산할 거고, 나머지 옵션들은 따로 opt로 계산한다

//자식의 경우 inf는 props:child로, 데이터의 경우 res 내부에 child 키로
export default {
	components: { cTd },
	props: {
		title: { default: '' },
		isChild: { default: false },

		inf: { default: false },
		parentItem: { default: false },
		res: { default: false },
		limit: { default: 10 },

		trClass: { default: '' },

		nav: { default: 'list' },
		//list more none

		selectable: { default: false },
		child: { default: false },
		checkBoxKey: { default: 'idx' },

		isHover: { default: true },
		isStriped: { default: true },
		cName: { default: 'tb' },
	},
	data() {
		return {
			checkAllStatus: false,
			currentOrder: {
				key: '',
				arrow: '',
			},

			selectedRow: '',

			checkedItem: [],
			checkBoxAll: [],
			checkBoxList: [],
			lockPagination: false,
			lastClickIdx: 0,

			keyForUpdate: 0,
		}
	},

	methods: {
		sel,

		getAriaSort(h) {
			//정렬 스타일 표시
			if (!h.sortable) return ''
			const key = h.sort ? h.sort : h.key
			if (this.currentOrder.key == key) return this.currentOrder.arrow + 'ending'
			return 'none'
		},
		changeOrder(h) {
			if (!h.sortable) return
			const key = h.sort ? h.sort : h.key
			let arrow = 'desc'
			if (this.currentOrder.key == key && this.currentOrder.arrow == 'desc') arrow = 'asc'
			const data = { key, arrow }
			this.$set(this, 'currentOrder', data)

			if (!this.isChild) {
				if (this.p.lastOpt) {
					this.p.lastOpt.orderBy = getOrder(key, arrow)
					reload(this.p)
				} else if (typeof this.p.changeOrder == 'function') this.p.changeOrder(h)
				else this.$emit('changeOrder', data)
			} else this.$emit('changeOrder', data)
		},

		isShowTd(opt, k) {
			if (opt.isChild) return false
			if (this.isSpan(opt[k])) return false
			return true
		},
		isSpan(opt) {
			if (typeof opt == 'object' && opt.isSpan) return true
			return false
		},
		getColspan(opt) {
			if (typeof opt == 'object' && opt.colspan) return opt.colspan
			return 1
		},
		getRowspan(opt) {
			if (typeof opt == 'object' && opt.rowspan) return opt.rowspan
			return 1
		},
		getTrClass(line, h) {
			const v = line.item,
				opt = line.opt
			//이건 child용 opt라 여기에 담긴다
			//trClass는 item에 직접 담길 수도 있고 props로 받을 수도 있다
			let res = this.selectable !== false && !opt.isChild ? 'cursor-pointer' : ''
			if (v == this.selectedRow) res += ' selectedRow'
			return `${res} ${sel(this.trClass, v, '')} ${sel(v.trClass, v, '')}`
		},
		getTdClass(line, h, key) {
			const v = line.item,
				opt = line.opt[key]
			//tdClass는 inf + res에
			//line = { item, lineOpt, opt }
			//res에 있던 것들은 line.opt에 들어간다
			let res = h && h.notCenter ? '' : 'text-center'
			res += ' ' + sel(h.tdClass, v, '')
			return res + ' ' + sel(opt.tdClass, v, '')
		},

		initCheck() {
			//체크박스 객체 가져와서 등록하고 이미 선택된 항목이면 체크 표시. 페이지 변경될 때마다 해줘야 한다
			//이건 이따가 다듬어야 한다
			setTimeout(() => {
				if (this.res && this.res.list) {
					this.checkBoxList = Array.from(this.$el.getElementsByClassName('baseCheckBox')).map(v => {
						const vue = v.__vue__
						vue.localChecked = this.isCheck(this.res.list[vue.name])
						return vue
					})

					this.checkBoxAll = this.$el.getElementsByClassName('baseCheckBoxAll')
					if (this.checkBoxAll.length) {
						this.checkBoxAll = this.checkBoxAll[0].__vue__
						this.checkBoxAll.localChecked = this.isCheckAll()
					}
				}
			}, 100)
		},
		isCheckAll() {
			//[ 모든 체크박스가 체크되었는지 여부, 체크박스 객체 ]
			return this.checkBoxList.every(v => v.localChecked) && this.checkBoxList.length != 0
		},
		clickCheckAll(isCheck = false) {
			//모든 체크박스 체크/해제 클릭 이벤트
			this.checkBoxList.map(v => {
				//체크박스 체크 처리
				v.localChecked = isCheck

				//선택한 항목에 추가/제거
				this.checkHandle(this.res.list[v.name], isCheck)
			})

			this.checkAllStatus = isCheck
			this.forceUpdate()
		},
		checkHandle(item, isCheck = true) {
			if (!item) return
			if (typeof item != 'object') item = this.res.list[item]

			let len = 1
			if (isCheck) this.checkedItem[item[this.checkBoxKey]] = item
			else {
				delete this.checkedItem[item[this.checkBoxKey]]
				len = -1
			}
			this.checkedItem.length = this.checkedItem.length + len
		},
		isCheck(item) {
			if (!item) return false
			//체크박스 클릭했을 때 해당 항목이 이미 선택된 항목인지 반환
			return typeof this.checkedItem[item[this.checkBoxKey]] != 'undefined'
		},
		clickCheck(item) {
			//단일 체크박스 체크/해제 클릭 이벤트
			this.checkHandle(item, !this.isCheck(item))
			this.checkAllStatus = this.isCheckAll()
		},
		getChecked(filter) {
			//선택 항목 배열로 반환
			let res = []
			for (const k in this.checkedItem) if (this.checkedItem[k]) res.push(this.checkedItem[k])

			if (filter) {
				if (typeof filter == 'function') res = res.map(v => filter(v))
				else res = res.map(v => v[filter])
			}
			return res
		},
		clearChecked() {
			this.checkedItem = []
			this.checkAllStatus = false
			this.forceUpdate()
		},

		selectRow(line, i) {
			if (this.selectable !== false && !line.opt.isChild) {
				//테이블 선택이 켜져 있으면서 자식 tr이 아닐 때 선택 가능
				this.selectedRow = line.item
				this.lastClickIdx = i
				this.$emit('click', line.item, i)
			}
		},

		openMemo(item, head) {
			openMemoPopup(head.domain, item, head)
		},

		childChangePage(page, item) {
			if (typeof this.p.child.changePage == 'function') this.p.child.changePage(item, page)
			else this.$emit('childChangePage', item, page)
		},
		childChangeOrder(data, item) {
			if (typeof this.p.child.changeOrder == 'function') this.p.child.changeOrder(item, page)
			else this.$emit('childChangeOrder', item, page)
		},

		copyText(val) {
			layout.copyText(val)
		},

		getInstaUrl(instaId) {
			let res = ''
			if (typeof instaId == 'string') {
				if (instaId.indexOf('instagram.com/') == -1) res = 'https://www.instagram.com/' + instaId
			}
			return res
		},
		forceUpdate() {
			this.keyForUpdate++
		},
	},
	computed: {
		info() {
			//컬럼 정보 배열 inf 및 thClass 계산
			return Array.isArray(this.inf)
				? this.inf.map(h => {
						const res = { ...h }

						let thClass = 'align-middle text-center py-2 '
						if (h.model == 'checkAll') thClass += 'w-1 '
						else if (h.size) thClass += `w-${h.size} `

						if (h.thClass) thClass += h.thClass
						res.thClass = thClass
						return res
				  })
				: []
		},
		keys() {
			//직접 명시된 key가 있다면 해당 키를, 아니라면 순번을 키로 사용
			return this.info.map((h, i) => (h.key ? h.key : i))
		},
		totalLength() {
			//항목의 총 개수
			return this.res && this.res.paging ? this.res.paging.totalCnt : 0
		},
		currentPage: {
			//현재 페이지 정보 반환 또는 페이지 이동
			get() {
				return this.res && this.res.paging ? this.res.paging.currentPage + 1 : 1
			},
			set(page) {
				if (!this.lockPagination) {
					//따로 처리를 안 해주면 사용자가 클릭한 페이지랑 얘가 가지고 있는 페이지랑 괴리가 생겨서 계속 페이지 변경이 일어난다..
					this.lockPagination = true
					if (!this.isChild && typeof this.p.changePage == 'function')
						this.p.changePage(page, this.parentItem)
					else this.$emit('changePage', page, this.parentItem)
				}
			},
		},
		totalPage() {
			//총 페이지 수
			return Math.ceil(this.totalLength / this.limit)
		},
		baseIdx() {
			//현재 페이지의 item이 시작하는 인덱스
			return this.totalLength - (this.currentPage - 1) * this.limit
		},

		lines() {
			if (!Array.isArray(this.res.list)) return []
			const spanCheck = [],
				res = []
			this.res.list.map((origin, i) => {
				//colspan 등 opt가 있는 경우 res.list가 객체 형태로 들어와야 한다
				//{ cont, colspan, rowspan, ... }
				const item = origin,
					lineOpt = { lineIdx: i, itemIdx: this.baseIdx - i }
				let opt = {}
				this.keys.map((key, j) => {
					//열 루프
					const position = `${i},${j}`,
						spanIdx = spanCheck.indexOf(position)
					opt[key] = { isSpan: spanIdx != -1 }

					if (typeof key == 'string') {
						//colspan, rowspan은 무조건 키가 있을 때만 적용하도록 한다
						//키가 명시되어 있으면서 해당 항목이 객체로 들어온 경우를 찾는다
						let v = item[key] ?? ''
						if (typeof item[key + 'Opt'] == 'object') {
							opt[key] = { ...opt[key], ...item[key + 'Opt'] }

							if (!opt[key].isSpan) {
								//이미 span 처리된 경우가 아닐 때만 span 옵션 있는지 검사
								const colspan = item[key + 'Opt'].colspan ?? 1,
									rowspan = item[key + 'Opt'].rowspan ?? 1
								if (colspan > 1 || rowspan > 1) {
									for (let ii = 0; ii < rowspan; ii++) {
										for (let jj = 0; jj < colspan; jj++) spanCheck.push(`${i + ii},${j + jj}`)
									}
								}
							}
						}
					}
				})
				opt.length = this.keys.length
				res.push({ item, lineOpt, opt, child: item.child ? item.child : false })

				if (item.child) {
					//자식이 있을 때 처리. 테이블의 striped 옵션이 켜져 있다면 안 보이는 라인이 하나 들어가야 한다
					if (this.isStriped)
						res.push({ item: {}, lineOpt: {}, opt: { isChild: 1, isShow: false }, p: {}, child: false })
					res.push({
						item: item.child,
						lineOpt: {},
						opt: {
							isChild: 1,
							isShow: true,
						},
						p: item,
						child: false,
					})
				}
				//return opt
			})
			return res
		},
	},
	watch: {
		res: {
			deep: true,
			handler(val) {
				this.initCheck()
				this.lockPagination = false
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.tb {
	.tbTooltip {
		position: absolute;
		top: calc(50% - 11px);
		right: 2px;
		padding: 0;
	}
}
</style>
