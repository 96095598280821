<template>
	<b-modal id="alertComp" v-model="opt.isShow" :header-bg-variant="opt.color" centered>
		<template #modal-title><p class="text-white" v-text="opt.title" /></template>
		<p class="p-2 whitespace-pre-line">{{ opt.cont }}</p>
		<template #modal-footer>
			<b-button
				v-for="v in opt.btn"
				v-if="v.text"
				:class="v.class"
				@click="
					v.func()
					opt.isShow = false
				"
				:variant="v.variant"
				size="sm"
				v-text="v.text"
			/>
		</template>
	</b-modal>
</template>

<script>
let defOpt
export default {
	props: { cName: { default: 'alert' } },

	data() {
		defOpt = {
			isShow: true,
			title: '알림',
			cont: '',
			btn: [
				{
					text: '확인',
					func: this.defFunc,
					variant: 'primary',
					class: 'float-right mr-4 w-1/5',
				},
			],
		}
		return { opt: { ...defOpt, isShow: false } }
	},

	created() {
		;[
			{ key: 's', color: 'primary' },
			{ key: 'w', color: 'warning' },
			{ key: 'e', color: 'danger' },
		].map(v => {
			this[v.key] = (cont = '', autoCloseTime, btnSetting, rewrite) => {
				this.opt = { ...copyVar(defOpt), cont, color: v.color }

				if (isFunc(btnSetting)) btnSetting(this.opt.btn)
				if (typeof rewrite == 'object') this.opt = { ...this.opt, ...rewrite }
				if (autoCloseTime) {
					setTimeout(() => {
						this.opt.isShow = false
					}, autoCloseTime)
				}
			}
		})
	},
	methods: {
		confirm(cont = '', okFunc, cancelFunc, btnSetting, rewrite) {
			this.opt = { ...copyVar(defOpt), cont, color: 'info' }
			this.opt.btn.push({
				text: '취소',
				func: this.defFunc,
				variant: 'gray',
				class: 'float-right mr-4 w-1/5',
			})

			if (isFunc(okFunc)) this.opt.btn[0].func = okFunc
			if (isFunc(cancelFunc)) this.opt.btn[1].func = cancelFunc

			if (isFunc(btnSetting)) btnSetting(this.opt.btn)

			if (typeof rewrite == 'object') this.opt = { ...this.opt, ...rewrite }
		},
		excel() {
			this.confirm(
				'엑셀 파일 생성이 요청되었습니다.\r\n‘다운로드 파일 관리 메뉴’에서 생성된 파일을 다운로드 하실 수 있습니다.\r\n‘다운로드 파일 관리 메뉴’로 이동하시겠습니까?',
				() => page('downloadList'),
				'',
				'',
				{ color: 'primary' }
			)
		},
		defFunc() {
			this.opt.isShow = false
		},
	},
}
</script>
