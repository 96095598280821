import originAxios from 'axios'
import jwt from 'pages/libs/jwt'
window.jwt = jwt
let isSendRefresh = false,
	waitRetry = []
originAxios.interceptors.request.use(c => {
	layout.isLoading = true
	c.baseURL = apiUrl
	if (!c.url.startsWith('http') && c.url[0] != '/') c.url = '/' + c.url
	if (c.url != apiUrl + '/api/auth/refresh') c.headers.Authorization = `Bearer ${jwt.getToken()}`
	c.validateStatus = status => status >= 200 && status < 300
	return c
})

function exceptionFormData(data) {
	if (data?.constructor?.name != 'FormData') return data
	//폼데이터의 경우
	const res = {}
	for (let pair of data.entries()) res[pair[0]] = pair[1]
	return res
}

function logData(res) {
	if (env.server == 'partner' && env.appMode == 'prod')
		//파트너센터 상용 서버가 아닐 때만 로그 찍기
		return
	const config = res?.config
	if (!config) return l('config 정보 찾을 수 없음')

	switch (config?.url) {
		case '/api/internalGateway':
			//internal API 호출/결과 받기만 하는 컨트롤러
			const data = JSON.parse(config?.data)
			if (data)
				l(
					`%c--------------------------\r\ninternal. ${data.httpMethod}: ${data.requestUrl}`,
					'color:#FF5E00',
					'\r\n↑',
					exceptionFormData(data?.requestDto)
				)
			else l('data를 찾을 수 없음')
			return true
		case '/api/system/downloadExcel':
			//엑셀 파일 다운로드
			l(`%c--------------------------\r\n엑셀 다운로드`, 'color:green', '\r\n↑', config?.params)
			return true
		default:
			if (config.url.indexOf('api/backoffice/') != -1) {
				//백오피스 백엔드로 통하는 api
				let data = config?.data ?? '[]'
				if (data?.constructor?.name != 'FormData') data = JSON.parse(data)
				l(
					`%c--------------------------\r\nbackoffice. ${config.method.toUpperCase()}: ${config.url}`,
					'color:#007bff',
					'\r\n↑',
					exceptionFormData(data)
				)
				return true
			}
		//기본 post axios
		//l(`%c--------------------------\r\naxios: ${c.url}`, 'color:blue', '\r\n↑', c.data)
	}
	return false
}

originAxios.interceptors.response.use(
	res => {
		layout.isLoading = false
		if (logData(res)) l('↓', res.data)
		return res
	},
	e => {
		layout.isLoading = false
		if (logData(e)) l('↓', e.response ? e.response : e)
		//에러가 있을 때만 가로채온다. 로그인 세션 해제되었을 때만 대응
		if (e.config) {
			if (e.response && e.response.status === 401) {
				//401이 뜬다면 토큰 갱신 후 보내본다
				const originalRequest = e.config
				if (!isSendRefresh) {
					isSendRefresh = true
					jwt.refreshToken()
						.then(({ accessToken }) => {
							isSendRefresh = false
							waitRetry.map(retry => retry())
							waitRetry = []
						})
						.catch(e => {
							isSendRefresh = false
						})
				}
				return new Promise(resolve => {
					waitRetry.push(() => {
						originalRequest.headers.Authorization = `Bearer ${jwt.getToken()}`
						resolve(originAxios(originalRequest))
					})
				})
			}
		}
		let msg = e
		if (e.response && e.response.data && e.response.data.message) msg = e.response.data.message
		throw msg
	}
)

function axiosCatch(e) {
	layout.isLoading = false
	let msg = ''
	if (e.code == '2040') msg = e.message ? e.message : '다운로드할 데이터가 없습니다.'
	else msg = e.message ? e.message : e
	if (typeof msg != 'string') msg = JSON.stringify(msg)
	;[
		{
			key: 'Bad Request',
			msg: '서버에 잘못된 값이 전달되었습니다.',
		},
		{
			key: 'code 500',
			msg: '내부 서버 오류.\r\n서버에서 데이터를 처리하는 데 문제가 있습니다.',
		},
		{
			key: 'timed out',
			msg: '서버 처리 시간 초과.\r\n너무 많은 데이터를 처리하려 하고 있습니다.',
		},
	].map(arr => {
		if (msg.indexOf(arr.key) != -1) msg = arr.msg
	})
	if (msg) alert.w(msg)
}

async function axios(url, data = {}, isCheckSuccess = true, isReturnDataOnly = true, headers = {}, rewrite = {}) {
	isSendRefresh = false
	return await originAxios({
		url,
		data,
		method: 'POST',
		headers,
		...rewrite,
	})
		.then(res => {
			//HTTP CODE 검사
			res = res.data
			//success 검사. isCheckSuccess가 false면 success 검사 안 함
			if (isCheckSuccess && typeof res == 'object' && typeof res.success != 'undefined')
				if (res.success != '0000') {
					const e = new Error(res.message)
					e.code = res.success
					throw e
				}
			return isReturnDataOnly ? res.data : res
		})
		.catch(e => {
			axiosCatch(e)
			throw e
		})
}

//export default axios

//=========여기까지가 기본 사용. 아래로는 인터널 통신 전용=========

const internalApi = {},
	methods = ['post', 'put', 'delete'] //, 'delete']
methods.map(v => {
	internalApi[v] = function (requestUrl, requestDto, isCheckSuccess, isReturnDataOnly, headers) {
		if (requestUrl.indexOf('api/backoffice/') != -1)
			//백오피스 백엔드로 통하는 api
			return axios(requestUrl, requestDto, isCheckSuccess, isReturnDataOnly, headers, { method: v.toUpperCase() })
		//인터널로 통하는 api
		return axios(
			'/api/internalGateway',
			{
				requestUrl: `${requestUrl.startsWith('/') ? '' : '/'}${requestUrl}`,
				httpMethod: v.toUpperCase(),
				requestDto,
			},
			isCheckSuccess,
			isReturnDataOnly,
			headers
		)
	}
})

//api = {post: ƒ, put: ƒ, update: ƒ, delete: ƒ}
//export const api = internalApi

export const postApi = internalApi.post
export const putApi = internalApi.put
//export const updateApi = internalApi.update
export const deleteApi = internalApi.delete

//엑셀 파일 받기 전용
export const downloadExcel = (seq, fileName) => {
	return axios(
		'/api/system/downloadExcel',
		{},
		false,
		false,
		{},
		{ method: 'POST', responseType: 'JSON', params: { seq, fileName } }
	).then(data => window.open(data.data))

	/*axios(
		'/api/system/downloadExcel',
		{},
		false,
		false,
		{},
		{ method: 'GET', responseType: 'blob', params: { seq, fileName } }
	).then(data => {
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob([data]))
		link.setAttribute('download', fileName)
		document.body.appendChild(link)
		link.click()
		link.remove()
	})*/
}

function randPath() {
	return `${new Date().getTime()}_${Math.random().toString(36).substr(2, 8)}`
}

//파일 업로드 전용
export async function uploadFile(file, fileType = 'banner', url = '/api/uploadS3', method = 'post', etcData = {}) {
	let formData = new FormData()
	if (file.length) file = file[0]
	if (!file || file.constructor.name != 'File') {
		alert.w('파일 형식이 아닙니다')
		return Promise.reject('파일 형식이 아닙니다')
	}
	file = new File([file], `${randPath()}_${file.name}`, { type: file.type })
	formData.append('file', file, file.name)
	formData.append('fileType', fileType)

	Object.keys(etcData).map(k => {
		formData.append(k, etcData[k])
	})

	if (!url.startsWith('/')) url = '/' + url
	return await originAxios[method.toLowerCase()](apiUrl + url, formData, {
		headers: {
			Authorization: `Bearer ${jwt.getToken()}`,
			'Content-Type': 'multipart/form-data',
		},
	})
		.then(res => {
			res = res.data
			if (res.success && res.success != '0000') {
				const e = new Error(res.message)
				e.code = res.success
				throw e
			}
			if (res.error) {
				alert.w('파일 업로드에 실패했습니다')
				return Promise.reject(res.error)
			}
			return res.url ? res.url : res
		})
		.catch(e => {
			axiosCatch(e)
			throw e
		})
}
