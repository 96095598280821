<template>
	<td>
		<slot v-if="vIf" :name="model" :h="h" :i="i" :j="j" :v="item"> <slot :h="h" :i="i" :j="j" :v="item" /> </slot>
	</td>
</template>
<script>
export default {
	props: {
		line: { default: false },
		k: { default: false },
		h: { default: false },
		i: { default: 0 },
		j: { default: 0 },
	},
	computed: {
		model() {
			return sel(this.h.model, this.item, 'default')
		},
		item() {
			if (!this.line) return {}
			return this.line.item
		},
		opt() {
			if (this.k === false) return {}
			return this.line.opt[this.k]
		},
		vIf() {
			return sel(this.h.vIf, this.item, true)
		},
	},
}
</script>
