import { postApi, putApi, deleteApi } from 'libs/axios'

window.postApi = postApi
window.putApi = putApi
window.deleteApi = deleteApi

window.isRestrictedDate = data => {
	//startDate, endDate, [idx, hottId]
	data = data.map(v => {
		const res = {
			startDate: v.startDate.replace(/-/g, '').substr(0, 8),
			endDate: v.endDate.replace(/-/g, '').substr(0, 8),
			productGroupIdx: v.productGroupIdx,
		}

		if (v.idx) res.dealRequestIdx = v.idx
		if (v.hottId) res.hottId = v.hottId
		return res
	})
	return postApi('api/backoffice/dealRestrictedDateSelect/validateDealRestrictedDate', data).then(res => {
		if (!res.length) return ''
		return res.map(v => `${v.hottId} / ${v.productGroupName} / ${v.dealRequestIdx}`).join('\r\n')
	})
}

window.openMemoPopup = (domain = 'MEMBER', item, head) => {
	layout.memoPopup.open(domain, item, head)
}

window.alert = {}
//윈도우 기본 알럿 덮어쓰기
;['s', 'w', 'e', 'excel'].map(v => {
	window.alert[v] = function (cont = '', autoCloseTime, btnSetting, rewrite) {
		if (Array.isArray(cont)) cont = cont.join('\r\n')
		layout.alert[v](cont, autoCloseTime, btnSetting, rewrite)
	}
})

//윈도우 기본 컨펌 덮어쓰기
window.confirm = function (cont = '', okFunc, cancelFunc, btnSetting, rewrite) {
	if (Array.isArray(cont)) cont = cont.join('\r\n')
	layout.alert.confirm(cont, okFunc, cancelFunc, btnSetting, rewrite)
}

window.getConfig = (category, notCutting = false, isSetAllOption = true) => {
	if (category) {
		if (typeof category == 'string') category = category.split(',').map(v => v.trim())
		return postApi('/operatingSelect/configValue', { saveStatus: 'Y', category }).then(res => {
			const result = {}
			res.map(v => {
				//category, orderNo, saveStatus, seqNo, title, valueCode ... 드롭다운이랑 형식도 안 맞고 쓸데 없는 정보가 너무 많다
				if (!notCutting)
					v.data = v.data.map(k => {
						return { label: k.title, value: k.valueCode }
					})

				result[v.categoryCode] = isSetAllOption ? [{ label: '전체', value: '' }, ...v.data] : v.data
			})
			return result
		})
	}
	return Promise.reject('category 잘못 들어옴')
}

window.page = (name, params = {}, isReplace = false) => {
	if (!name) return false
	const method = isReplace ? 'replace' : 'push'
	let data
	if (typeof name == 'string') data = { name, params }
	else data = { ...name, ...params }
	root.$router[method](data)
}

window.refresh = (delay = 1000, route = root.$route) => {
	if (route.name != 'login')
		setTimeout(() => {
			page('home', {}, true)
			setTimeout(() => {
				page(route.name, route.params, true)
			}, 100)
		}, delay)
}

import Vue from 'vue'

Vue.config.productionTip = false
Vue.mixin({
	beforeCreate() {
		this.ths = this
	},

	props: { pr: { default: false }, cName: { default: false }, isRewrite: { default: true } },
	data() {
		//부모 인계 받기
		const p = this.pr ? this.pr : this.$parent

		//부모 컴포넌트에 자신 등록
		let name = typeof this.cName == 'string' ? this.cName : this.dName
		if (name) {
			//기본적으로 이름이 있을 때 등록함
			//.이름 으로 등록이 되는데, 여러 개가 있다면 이름2 이름3 식으로 등록함
			if (typeof p == 'object') {
				if (!this.isRewrite && p[name]) {
					const idxName = `${name}ChildCt`,
						idx = p[idxName] ? p[idxName] * 1 + 1 : 2
					p[idxName] = idx
					name += idx
				}
				p[name] = this
			}
		}

		return {
			p,
		}
	},

	created() {},
	computed: {},
	methods: {
		page,
		comma,
		num,
		numFormat,
		l,
	},
})

import datePicker from 'comp/datePicker'

Vue.component('datePicker', datePicker)

import searchCard from 'comp/searchCard'

Vue.component('searchCard', searchCard)

import tb from 'comp/tb'

Vue.component('tb', tb)

import vtb from 'comp/vtb'

Vue.component('vtb', vtb)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.component('vSelect', vSelect)

//부트스트랩 뷰 시작
//근데 이거 필요한 플러그인만 사용하도록 조정했는데 생각보다 차이가 없다..?
//해당 부분에 따라 bootstrap.scss도 수정해줘야 한다
import {
	CardPlugin,
	ModalPlugin,
	IconsPlugin,
	ImagePlugin,
	TablePlugin,
	PaginationPlugin,
	CollapsePlugin,
	DropdownPlugin,
	//////////////////////
	FormInputPlugin,
	FormCheckboxPlugin,
	FormRadioPlugin,
	FormTextareaPlugin,
	FormFilePlugin,
	ButtonPlugin,
	/////////////////////
	BadgePlugin,
	AvatarPlugin,
	BreadcrumbPlugin,
	SpinnerPlugin,
	VBHoverPlugin,
	TooltipPlugin,
} from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)
Vue.use(ImagePlugin)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)

Vue.use(FormInputPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormFilePlugin)
Vue.use(ButtonPlugin)
Vue.use(BadgePlugin)

Vue.use(AvatarPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(SpinnerPlugin)
Vue.use(VBHoverPlugin)
Vue.use(TooltipPlugin)

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//Vue.use(BootstrapVue)
//Vue.use(IconsPlugin)

//import 'bootstrap/dist/css/bootstrap.min.css'
//부트스트랩 5버전은 아무리 해도 이 스타일이 안 먹힌다;; 어느 정도는 적용이 되는데 뭔가 이상함...
require('libs/bootstrap.scss')
//import 'bootstrap/dist/css/bootstrap.rtl.css'
//import 'bootstrap/dist/css/bootstrap-grid.css'
//import 'bootstrap/dist/css/bootstrap-grid.rtl.css'
//import 'bootstrap/dist/css/bootstrap-reboot.css'
//import 'bootstrap/dist/css/bootstrap-reboot.rtl.css'
//import 'bootstrap/dist/css/bootstrap-utilities.css'
//import 'bootstrap/dist/css/bootstrap-utilities.rtl.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

import VueRouter from 'vue-router'
import menuItems from 'pages/libs/menuItems'
import jwt from 'pages/libs/jwt'

Vue.use(VueRouter)
const router = new VueRouter({
	mode: 'history',
	base: env.BASE_URL,
	routes: menuItems(),
})

router.beforeEach((to, from, next) => {
	//l(to, from, next)
	if (layout.memoPopup) {
		//팝업들 초기화
		layout.memoPopup.isShow = false
		layout.alert.opt.isShow = false
	}
	layout.pageTitle = ''

	//acl 네비게이션 가드 이걸로 변경.. vue-acl의 미들웨어는 일부는 비로그인 상태로도 접속 가능한 부분을 설정하기가 힘들다..
	const routeRule = to.meta.rule
	if (routeRule != 'notLogin') {
		jwt.isLogin().then(() => next())
	} else next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err
		else refresh(0, err.to)
	})
}

import { AclInstaller, AclCreate } from 'vue-acl'

Vue.use(AclInstaller)
const userInfo = jwt.getUserInfo()
let roleGroup
if (userInfo) roleGroup = userInfo.roleGroup
else roleGroup = rule.notLogin

const acl = new AclCreate({
	initial: roleGroup,
	notfound: {
		path: '/login',
		forwardQueryParams: true,
	},
	router,
	acceptLocalRules: false,
	globalRules: {
		adminOnly: [[rule.admin]],
		moreThanAccounting: [[rule.admin], [rule.accounting]],
		moreThanHottistManager: [[rule.admin], [rule.hottistManager]],
		moreThanMember: [[rule.admin], [rule.accounting], [rule.hottistManager], [rule.member], [rule.manager]],
		moreThanDelivery: [
			[rule.admin],
			[rule.accounting],
			[rule.hottistManager],
			[rule.member],
			[rule.manager],
			[rule.delivery],
		],
		notLogin: [
			[rule.admin],
			[rule.accounting],
			[rule.hottistManager],
			[rule.member],
			[rule.manager],
			[rule.notLogin],
		],
	},
})

import layoutTag from 'comm/layout'
window.root = new Vue({
	router,
	render: h => h(layoutTag),
}).$mount('#layout')
window.layout = root.$children[0]
