//부모에 들어갈 수 있는 값. *는 필수값
//file, name*, icon*, admin, hide, meta, appendUrl, parent, sub
//아이콘 정보 : https://bootstrap-vue.org/docs/icons#icons-1
//sub에 들어갈 수 있는 값. *는 필수값
//file*, name*, meta, appendUrl
const adminOnly = 'adminOnly',
	moreThanAccounting = 'moreThanAccounting',
	moreThanMember = 'moreThanMember',
	moreThanDelivery = 'moreThanDelivery',
	moreThanHottistManager = 'moreThanHottistManager',
	notLogin = 'notLogin'

const items = [
	{
		file: 'home',
		name: 'Home',
		icon: 'house',
		rule: moreThanDelivery,
	},
	{
		file: 'login',
		name: 'Login',
		routerOnly: 1,
		rule: notLogin,
	},
	{
		name: '주문 관리',
		icon: 'basket2',
		rule: moreThanDelivery,
		sub: [
			{
				file: 'orderList',
				name: '주문 배송 리스트 관리',
				rule: moreThanDelivery,
			},
			{
				file: 'orderSponsoredList',
				name: '협찬품 배송 리스트 관리',
				rule: moreThanDelivery,
			},
		],
	},
	{
		file: 'orderDetail',
		name: '주문 상세',
		routerOnly: 1,
		parent: 'orderList',
		appendUrl: '/:orderNum',
		rule: moreThanDelivery,
	},
	{
		file: 'orderSponsoredDetail',
		name: '협찬품 상세 정보',
		routerOnly: 1,
		parent: 'orderSponsoredList',
		appendUrl: '/:dealRequestIdx',
	},
	{
		name: '핫티스트 관리',
		icon: 'person',
		sub: [
			{
				file: 'hottistExamList',
				name: '핫티스트 심사',
			},
			{
				file: 'firstDealList',
				name: '스타터 첫딜 매칭 관리',
				exp: '핫티스트 승인 후 딜 진행이력이 없는 핫티스트의 목록입니다. 컨택하여 첫딜을 매칭하세요.',
			},
			{
				file: 'graduateList',
				name: '스타터 수료 관리',
				exp: '딜을 진행 중인 스타터 목록입니다. 기준을 충족한 스타터는 ‘수료’버튼을 눌러 수료시킬 수 있습니다.',
			},
			{
				file: 'hottistList',
				name: '핫티스트 조회 · 수정',
				appendUrl: '/:hottId',
			},
			{
				file: 'penaltyList',
				name: '페널티 관리',
			},
		],
	},
	{
		name: '딜 관리',
		icon: 'archive',
		sub: [
			{
				file: 'dealList',
				name: '딜 리스트 조회',
				appendUrl: '/:searchId',
			},
			{
				file: 'sponsoredList',
				name: '협찬시트',
			},
			{
				file: 'dealRestrictedDateList',
				name: '딜 불가기간 등록',
			},
		],
	},
	{
		name: '수동딜 관리',
		icon: 'wifi',
		sub: [
			{
				file: 'manualDealList',
				name: '수동딜 관리',
			},
			{
				file: 'manualDealAdd',
				name: '수동딜 등록',
				appendUrl: '/:idx',
			},
			{
				file: 'testDealAdd',
				name: '테스트딜 등록',
			},
			{
				file: 'proposalDealList',
				name: '자동 선제안 관리',
			},
		],
	},
	{
		file: 'contentsList',
		name: '콘텐츠 관리',
		icon: 'instagram',
	},
	{
		name: '파트너사 관리',
		icon: 'building',
		sub: [
			{
				file: 'partnerList',
				name: '파트너사 리스트',
			},
			{
				file: 'partnerAdd',
				name: '파트너사 등록',
				appendUrl: '/:storeSeq',
			},
			/*{
				file: 'downloadAdData',
				name: '광고 데이터 다운로드',
			},*/
			{
				file: 'partnerDetail',
				name: '파트너사 상세',
				parent: 'partnerList',
				appendUrl: '/:storeSeq',
			},
			{
				file: 'partnerEdit',
				name: '파트너사 수정',
				parent: 'partnerList',
				appendUrl: '/:storeSeq',
			},
		],
	},
	{
		name: '상품 관리',
		icon: 'box-seam',
		sub: [
			{
				file: 'productList',
				name: '상품 리스트',
			},
			{
				file: 'productAdd',
				name: '상품 등록',
				appendUrl: '/:productGroupIdx',
			},
			{
				file: 'requestProductList',
				name: '희망상품 요청 리스트',
			},
			{
				file: 'reviewList',
				name: '리뷰 관리',
			},
			{
				file: 'couponList',
				name: '쿠폰 관리',
				rule: moreThanHottistManager,
			},
			{
				file: 'couponAdd',
				name: '쿠폰 등록',
				parent: 'couponList',
				appendUrl: '/:couponSeq',
				rule: moreThanHottistManager,
			},
			{
				file: 'couponDistribute',
				name: '쿠폰 발급',
				rule: moreThanHottistManager,
			},
		],
	},
	{
		name: '배너 관리',
		icon: 'image',
		sub: [
			{
				file: 'banner',
				name: '핫딜센터 메인배너',
				appendUrl: '/deal',
			},
			{
				file: 'banner',
				name: '핫트메인 상단배너',
				appendUrl: '/main_top',
			},
		],
	},
	{
		name: '운영 관리',
		icon: 'clipboard-minus',
		sub: [
			{
				file: 'downloadAdminData',
				name: '운영 데이터 다운로드',
			},
			{
				file: 'notice',
				name: '공지사항 관리',
			},
			/*{
				file: 'appPushSend',
				name: '앱푸시 발송',
			},*/
			{
				file: 'memoList',
				name: '메모 조회',
			},
		],
	},
	/*{
		name: '안심번호 관리',
		icon: 'phone',
		sub: [
			{
				file: 'securityNumList',
				name: '안심번호 리스트',
				exp: '현재 사용 중인 안심번호의 목록입니다. 안심번호 사용현황을 확인하세요.',
			},
			{
				file: 'securityNumHistory',
				name: '안심번호 히스토리',
				exp: '안심번호 등록, 삭제 히스토리 목록입니다.',
			},
			{
				file: 'securityNumMap',
				name: '수동 연결/해제',
				exp: '안심번호를 수동으로 연결/해제합니다.',
			},
		],
	},*/

	{
		name: '템플릿 관리',
		icon: 'envelope-open',
		rule: adminOnly,
		sub: [
			{
				file: 'templateTalk',
				name: '알림톡 템플릿 관리',
			},
			{
				file: 'templateEmail',
				name: '이메일 템플릿 관리',
			},
			{
				file: 'templateAppPush',
				name: '앱푸시 발송 관리',
			},
		],
	},
	{
		file: 'accounting',
		name: '핫티스트 정산',
		icon: 'credit-card',
		rule: moreThanAccounting,
	},
	{
		file: 'downloadList',
		name: '다운로드 관리',
		icon: 'box-arrow-in-down',
		exp: '다운로드 요청한 엑셀 파일을 조회하고 다운로드할 수 있습니다. 데이터 양에 따라 파일 생성이 오래 걸릴 수 있습니다.',
		rule: moreThanDelivery,
	},
	{
		file: 'adminList',
		name: '권한 관리',
		icon: 'lock',
		rule: adminOnly,
	},
]
const getRouterMenu = (v, p = false) => {
		let url = false,
			name = '',
			rule = moreThanMember
		if (v.file) {
			if (!v.url) {
				url = upToDash(v.file)
				name = v.file
			} else {
				url = upToDash(v.url)
				name = v.url
			}
		}
		if (v.rule) rule = v.rule
		else if (p && p.rule) rule = p.rule

		let res = {
			meta: {
				breadcrumb: p ? [{ text: p.name }] : [],
				pageTitle: v.name,
				rule,
				exp: v.exp,
			},
		}
		res.meta.breadcrumb.push({ text: v.name, active: true })
		//to

		if (v.meta) res.meta = { ...res.meta, ...v.meta }

		if (v.parent) res.meta.parent = v.parent

		if (url) {
			let path = '/' + url
			if (v.appendUrl) {
				if (!v.appendUrl.startsWith('/')) path += '/'

				path += v.appendUrl
				if (v.appendUrl.indexOf(':') != -1) {
					path += '?'
					res.props = true
				}
			}
			res = {
				...res,
				path,
				name,
				component: () => import('pages/main/' + v.file),
			}
		}
		return res
	},
	getNavMenu = (v, p = false) => {
		if (!v.routerOnly) {
			let url = false,
				res = { title: v.name, expand: false },
				rule = moreThanMember
			if (v.file) {
				//url = upToDash(v.file)
				res.route = '/' + upToDash(v.file)
				if (v.appendUrl) {
					if (v.appendUrl.indexOf(':') == -1) res.route += v.appendUrl
				}
				//인자로 받는 값이 아니라면 뒤에 추가
				res.parent = v.file
			}

			if (!p) {
				if (v.icon) res.icon = v.icon
				if (v.file) res.parent = v.file
			}

			if (v.rule) rule = v.rule
			else if (p && p.rule) rule = p.rule
			res.rule = rule

			return res
		}
		return false
	}

export default (isRouter = true) => {
	const res = []
	items.map(v => {
		const pMenu = isRouter ? getRouterMenu(v) : getNavMenu(v)
		if (pMenu) {
			if (v.sub) {
				//서브 메뉴가 있다면
				if (isRouter)
					//라우터에는 말단 페이지만 등록되기 때문에 서브 페이지를 가지는 부모의 정보는 등록되지 않는다
					v.sub.map(s => {
						res.push(getRouterMenu(s, v))
					})
				else if (!v.hide) {
					//메뉴는 부모에게 submenu 배열로 들어감
					pMenu.children = v.sub.filter(v => !(v.parent || v.routerOnly)).map(s => getNavMenu(s, v))
					res.push(pMenu)
				}
			} else if (isRouter || !v.hide) res.push(pMenu)
		}
	})
	if (isRouter) res.push({ path: '*', redirect: '/login' })
	if (env.server != 'partner' || env.appMode != 'prod') {
		//파트너센터 상용 서버가 아닐 때만 로그 찍기
		l(isRouter ? '--------router--------' : '--------menu--------')
		l(res)
	}
	return res
}
