import Vue from 'vue'
import axios from 'axios'
import { encode, decode } from 'js-base64'

const tokenKey = 'sbboactk',
	refreshKey = 'sbborftk',
	userKey = 'sbboui2'

export default {
	isLogin() {
		return axios.get(apiUrl + '/api/system/checkLogin', {})
	},
	login(user) {
		//이따 구글에서 주는 고유값을 사용하도록 바꿔야 한다
		const profile = user.getBasicProfile(),
			data = {
				name: profile.getName(),
				email: profile.getEmail(),
				imgUrl: profile.getImageUrl(),
			}

		if (data.email.split('@')[1] !== 'socialbean.co.kr') {
			const e = '소셜빈 임직원만 가입 및 로그인할 수 있습니다.'
			alert.w(e)
			throw new Error(e)
		} else
			return axios
				.post(apiUrl + '/api/auth/oauth/login', data, {
					withCredentials: true,
				})
				.then(res => {
					res = res.data
					localStorage.setItem(tokenKey, res.accessToken)
					localStorage.setItem(refreshKey, res.refreshToken)
					res.userData.profileImage = data.imgUrl
					//서버에서 이건 또 왜 안 준다냐..
					localStorage.setItem(userKey, encode(JSON.stringify(res.userData)))
					layout.userUpdateFl++
					root.$acl.change(res.userData.roleGroup)
				})
				.catch(e => {
					errorExcution(e, data)
					throw new Error(e)
				})
	},
	logout() {
		axios
			.post(apiUrl + '/api/auth/logout', {
				/*t: storage.getRefreshToken(),
				 id: storage.getUserInfo().id,*/
			})
			.then(v => page('login'))
		l('로그아웃 처리')
		//라우터 이동
		localStorage.setItem(tokenKey, '')
		localStorage.setItem(refreshKey, '')
		localStorage.setItem(userKey, '')
		layout.userUpdateFl++
		root.$acl.change(rule.notLogin)
		page('login')
		layout.isFullPage = true
		return Promise.reject('')
	},

	getToken() {
		return localStorage.getItem(tokenKey)
	},
	refreshToken() {
		const token = localStorage.getItem(refreshKey)
		if (token)
			return axios
				.post(
					apiUrl + '/api/auth/refresh',
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(({ data }) => {
					localStorage.setItem(tokenKey, data.accessToken)
					return data
				})
				.catch(e => {
					l('refresh 실패')
					l(e)
					return this.logout()
				})
		return this.logout()
	},

	getUserInfo() {
		const userInfo = localStorage.getItem(userKey)
		return userInfo ? JSON.parse(decode(userInfo)) : false
	},
}

function errorExcution(e, requestData) {
	e = e.response && e.response.data && e.response.data.code ? e.response.data.code : ''
	switch (e) {
		case 'NOT_EXIST':
			confirm('가입한 적 없는 계정입니다. 가입을 진행하시겠습니까?', () => {
				axios
					.post(apiUrl + '/api/auth/oauth/signup', requestData, { withCredentials: true })
					.then(() => {
						alert.s('관리자에게 가입이 요청되었습니다.\r\n관리자 승인 후 로그인 할 수 있습니다.')
					})
					.catch(e => {
						errorExcution(e)
					})
			})
			break
		case 4001:
		case 'WAIT':
			//아니.. 여기도 파편화가...
			alert.w('이미 신청한 사용자입니다.\r\n관리자 승인 후 로그인 할 수 있습니다.')
			break
		default:
			alert.w('요청이 실패했습니다. 반복시 관리자에게 문의해주세요.')
	}
}
