<template>
	<b-card class="mb-4 searchCard">
		<slot />
		<b-button class="absolute right-0 top-0" @click="resetFilter" size="sm" variant="transparent">
			<b-icon icon="arrow-clockwise" scale="1.2" />
		</b-button>
	</b-card>
</template>

<script>
export default {
	props: ['reset'],
	methods: {
		resetFilter() {
			if (typeof this.reset == 'function') this.reset()
			else if (this.p.opt && this.p.defOpt) this.p.opt = { ...this.p.defOpt }
			else l('reset이 정의되지 않음')
		},
	},
}
</script>
